var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.connectionInfo && _vm.show
    ? _c(
        "div",
        { attrs: { id: "connection-info" } },
        [
          _c(
            "div",
            {
              class: _vm.connectionInfo.type,
              attrs: { id: "connection-icon" },
            },
            [_vm._v("\n    " + _vm._s(_vm.connectionInfo.match) + "\n  ")]
          ),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: "status-icon",
                placement: "right",
                offset: "60",
              },
            },
            [
              _c("div", [
                _c("strong", [_vm._v("db: ")]),
                _vm._v(
                  _vm._s(
                    _vm.connectionInfo.db === ""
                      ? "*protected*"
                      : _vm.connectionInfo.db
                  ) + "\n    "
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }